@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap&subset=cyrillic');

html,
body {
	padding: 0px;
	margin: 0px;
	font-weight: 300;
	background-color: #efefef;
	font-family: Helvetica, Arial, sans-serif !important;
}

html.font-loaded,
body.font-loaded {
	font-family: Montserrat, Helvetica, Arial, sans-serif !important;
}


/* http://labs.enonic.com/articles/remove-forced-yellow-input-background-in-chrome */

input:-webkit-autofill {
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: inset 0 0 0 50px #fff !important;
}

input,
textarea,
select,
button {
	font-family: inherit;
}

.ReactTable {
	border: none;
	font-size: 12px;
	font-weight: 500;
}

.ReactTable>.rt-noData {
	top: calc((100% - 60px)/2 + 60px);
}

.ReactTable .rt-table {
	border-radius: 3px;
}

.ReactTable .rt-table .rt-tbody {
	overflow: overlay
}

.ReactTable .rt-table .rt-thead .rt-th:focus {
	outline: none;
}

.ReactTable .rt-table .rt-thead .rt-resizable-header-content {
	line-height: 24px;
}

.ReactTable .rt-table .rt-tr-group {
	flex: 0 0 auto;
}

.ReactTable .rt-table .rt-tr {
	min-height: 60px;
	line-height: 46px;
}

.ReactTable .rt-table .rt-tr:first-child {
	padding-left: 24px;
}

.ReactTable .rt-table .rt-tr:last-child {
	padding-right: 24px;
}

.ReactTable .rt-table .rt-expandable {
	display: none;
}

.highcharts-container {
	margin: auto -9px;
	width: 100% !important;
	height: 100% !important;
}

.highcharts-container svg {
	width: 100% !important;
	height: 100% !important;
}